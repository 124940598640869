<template>
  <v-container v-if="vueVariaveis && super_admin">
    <v-row>
      <v-col cols="12">
        <PageToolbar title="Variáveis" icon="code" dark />
      </v-col>

      <v-col cols="12">
        <ListaCampos />
      </v-col>
    </v-row>
  </v-container>
  <NaoAutorizado v-else />
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "Configs",
  components: {
    ListaCampos: () => import("./components/ListaCampos.vue"),
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vueVariaveis() {
      return this.getAccess("vueVariaveis", "menu");
    },
    ...mapState("Usuario", {
      super_admin: (state) => state.usuario.super_admin,
    }),
  },
  methods: {
    ...mapActions("Tocsconfig", {
      reset: "reset",
    }),
  },
  mounted() {},
};
</script>

<style lang="scss" scoped></style>
